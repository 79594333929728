<template>
	<div class="needBox" v-loading='loading'>
		<div class="infortion1">需求信息</div>
		<div class="infortion_contail">
			<div><span>需求名称：</span><span>{{detailInfo.Title}}</span></div>
			<div style="margin:15px 0;"><span>需求状态：</span><span>{{detailInfo.StateValue}}</span></div>
			<div class="line" style="height: 1px;border-bottom: 1px solid #F5F5F5;"></div>
			<div class="classname" v-html="detailInfo.Content" style="margin-top: 15px;">
			</div>
		</div>
		<!-- v-if='detailInfo.IsReply' -->
		<div class="infortion1" v-if="detailInfo.IsReply">需求回复</div>
		<div class="infortion_contail" v-html="detailInfo.ReplyContent">
			<!-- {{detailInfo.ReplyContent}} -->
		</div>
	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		mallDemandDetail
	} from '@/api/TurnTomySelf'
	export default {
		data() {
			return {
				loading: false,
				detailId: 0,
				detailInfo: {}
			}
		},
		beforeMount() {
			this.getDeatail()
		},
		created() {
			console.log('没有执行吗8888')
			this.detailId = this.$route.query.Id ? this.$route.query.Id : 0;
		},
		methods: {
			async getDeatail() {
				try {
					this.loading = true
					let data = {
						Id: this.detailId
					}
					let result = await mallDemandDetail(data)
					if (result.IsSuccess) {
						this.detailInfo = result.Result
						console.log(result, '需求详情')
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			}
		}
	}
</script>

<style lang="less">
	.needBox {
		height: 100%;
		background: #fff;
		font-size: 15px;

		.infortion1 {
			padding: 10px 0 10px 10px;
			border-bottom: 1px solid #F5F5F5;
			margin-bottom: 20px;
		}

		.infortion_contail {
			padding-left: 30px;
			padding-bottom: 15px;
			// border-bottom: 1px solid #F5F5F5;
		}

	}
</style>
